import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { Box, Divider, Grid, Paper, Typography,Autocomplete } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import image2 from "../../../images/logo.webp";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "../../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";

const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function EditDoctorsDialog({getDoctors}) {
  const [status, setStatus] = React.useState("");
  const [availability, setAvailability] = React.useState("");
  const [gender, setGender] = React.useState("");

  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const availabilityChange = (event) => {
    setAvailability(event.target.value);
  };

  const GenderChange = (event) => {
    setGender(event.target.value);
  };

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");

  const alert = useAlert();
  const [image, setImage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [Description, setDescription] = React.useState("");
  const [educational, setEducational] = React.useState("");
  const [professional, setProfessional] = React.useState("");
  const [departments, setDepartments] = React.useState([""]);
  const [department, setDepartment] = React.useState([""]);
  const [specializes, setSpecializes] = React.useState([""]);
  const [specialize, setSpecialize] = React.useState([""]);
  const [languages, setLanguages] = React.useState([""]);
  const [language, setLanguage] = React.useState([""]);

  const [uploadImage, setUploadImage] = useState("");
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  React.useEffect(() => {
    getDepartments();
    getSpecializes();
    getLanguages();
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const setDepartmentSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setDepartment(arr);
  };


  const setSpecializeSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setSpecialize(arr);
  };


  const setLanguageSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setLanguage(arr);
  };


  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("id", saahe_id);
    formdata.append("title", title);
    formdata.append("logo", image);
    formdata.append("Description", Description);
    formdata.append("availability", availability);
    formdata.append("gender", gender);
    formdata.append("professional_membership", professional);
    formdata.append("education_detail", educational);
    formdata.append("departments", JSON.stringify(department));
    formdata.append("specializes", JSON.stringify(specialize));
    formdata.append("languages", JSON.stringify(language));
    formdata.append("status", status);
    axios(`Saahe/add_doctors`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getDoctors(saahe_id);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };


  const getDepartments = () => {
    axios(`Saahe/get_departments`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setDepartments(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getSpecializes = () => {
    axios(`Saahe/get_specializes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setSpecializes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getLanguages = () => {
    axios(`Saahe/get_languages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setLanguages(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddIcon color="inherit" /> Add Doctors
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new Doctors</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={departments}
                getOptionLabel={(option) => option.name}
                value={departments.filter(
                  (departments) => department && department.includes(departments.id)
                )}
                onChange={(e, value) => {
                  setDepartmentSelect(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Departments" />
                )}
              />
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={specializes}
                getOptionLabel={(option) => option.name}
                value={specializes.filter(
                  (specializes) => specialize && specialize.includes(specializes.id)
                )}
                onChange={(e, value) => {
                  setSpecializeSelect(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Specializes" />
                )}
              />
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={languages}
                getOptionLabel={(option) => option.name}
                value={languages.filter(
                  (languages) => language && language.includes(languages.id)
                )}
                onChange={(e, value) => {
                  setLanguageSelect(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Languages" />
                )}
              />
            </FormControl>{" "}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Status"
                  onChange={GenderChange}
                >
                  <MenuItem value={1}>Male</MenuItem>
                  <MenuItem value={2}>Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Availability</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={availability}
                  label="Status"
                  onChange={availabilityChange}
                >
                  <MenuItem value={1}>Full Time</MenuItem>
                  <MenuItem value={2}>Part Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={StatushandleChange}
                >
                  <MenuItem value={1}>Enable</MenuItem>
                  <MenuItem value={2}>Disable</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Education Detail"
                multiline
                rows={4}
                value={educational}
                onChange={(e) => setEducational(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Professional Membership"
                multiline
                rows={4}
                value={professional}
                onChange={(e) => setProfessional(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">Profile Image</Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
