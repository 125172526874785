import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MyAccountManageListingRegister from "./MyAccountManageListingRegister";
import MyAccountPagesCategory from "./MyAccountPagesCategory";
import { Grid } from "@mui/material";
import { useAlert } from "react-alert";
import axios from "../utils/Axios"; //import axios config

const theme = createTheme({
  palette: {
    primary: {
      light: '#B5AC68',
      main: "#1E3C55",
      dark: '#7C7C64',
      contrastText: '#fff',
    },
    secondary: {
      light: '#949866',
      main: '#7C7C64',
      dark: '#1E3C55',
      contrastText: '#000',
    },
  },
});
const steps = ["Choose Business Type ", "Registration Details"];

//const theme = createTheme();

export default function MyAccountPagesStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [checkedItem, setcheckedItem] = React.useState(0);
  const alert = useAlert();

  const [list, setList] = React.useState({});
  const [proimage, setLogo] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [content, setDescription] = React.useState("");
  const [city, setCities] = React.useState("");
  const [specialize, setSpecializ] = React.useState("");
  const [languages, setLanguag] = React.useState("");
  const [availability, setAvailability] = React.useState("");
  const [gender, setGender] = React.useState("");
  

    function getStepContent(step) {
      switch (step) {
        case 0:
          return <MyAccountPagesCategory checkedItem={checkedItem} setcheckedItem={setcheckedItem}/>;
        case 1:
          return <MyAccountManageListingRegister checkedItem={checkedItem} setList={setList} list={list} 
          setLogo={setLogo} setBanner={setBanner} setDescription={setDescription} setSpecializ={setSpecializ} setLanguag={setLanguag} setAvailability={setAvailability} setGender={setGender}/>;
        default:
          throw new Error('Unknown step');
      }
    }

    const handleNext = (event) => {
      if (checkedItem === 0) {
        alert.error("Select any Category");
      } else {
      setActiveStep(activeStep + 1);
      if (activeStep === 1) {
        setActiveStep(activeStep);
        event.preventDefault();
        let formdata = new FormData();
        formdata.append("type", checkedItem);
        formdata.append("logo", proimage);
        formdata.append("banner", banner);
        formdata.append("description", content);
        formdata.append("city", city);
        formdata.append("list", JSON.stringify(list));
        if(checkedItem!=3)
        {
        axios(`Saahe/add_organization`, {
          method: "POST",
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
            "x-api-key": JSON.parse(localStorage.getItem("data")),
          },
        })
          .then((res) => {
            if (res.data.status) {
              console.log(res.data.data);
              alert.success("Successfully Added");
              setActiveStep(activeStep + 1);
            } else {
              alert.error(res.data.message);
            }
          })
          .catch((err) => console.log(err));
        }
        else
        {
          formdata.append("specializes", JSON.stringify(specialize));
          formdata.append("languages", JSON.stringify(languages));
          formdata.append("gender", gender);
          formdata.append("availability", availability);
          axios(`Saahe/add_expert`, {
            method: "POST",
            data: formdata,
            headers: {
              "Content-Type": "multipart/form-data",
              "x-api-key": JSON.parse(localStorage.getItem("data")),
            },
          })
            .then((res) => {
              if (res.data.status) {
                console.log(res.data.data);
                alert.success("Successfully Added");
                setActiveStep(activeStep + 1);
              } else {
                alert.error(res.data.message);

              }
            })
            .catch((err) => console.log(err));
        }
        
      }
      }
    };
  
    const handleBack = () => {
      setActiveStep(activeStep - 1);
    };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper variant="outlined" sx={{ p: { xs: 2, md: 3 } }}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography variant="h5" gutterBottom>
                    Thank you for your registration.
                  </Typography>
                  <Typography variant="subtitle1">
                    Successfully Added
                  </Typography>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep)}
                  <Box
                    className="MyAccountProfileBg"
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                        Back
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      className="primaryButton"
                      onClick={handleNext}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      {activeStep === steps.length - 1 ? "submit" : "Next"}
                    </Button>
                  </Box>
                </React.Fragment>
              )}
            </React.Fragment>
          </Paper>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
