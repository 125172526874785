import * as React from "react";
import {BrowserRouter,Routes,Route,Navigate} from "react-router-dom";
import "./App.css";
import Detail from "./components/detail/Detail";
import StoriesDetail from "./components/detail/stories/StoriesDetail";
import Home from "./components/home/Home";
import Listing from "./components/listing/Listing";
import ListingMap from "./components/listing/ListingMap";
import Map from "./components/listing/Map";
import MyAccount from "./components/myaccount/MyAccount";
import MyAccountEdit from "./components/myaccount/edit/MyAccountEdit";
import Login from "./components/pages/Login";
import Registration from "./components/pages/Registration";
import Settings from "./components/pages/Settings";
import ForgotPassword from "./components/pages/ForgotPassword";
import PackagesDetail from "./components/detail/Packages/PackagesDetail";
import DoctorProfile from "./components/detail/DoctorProfile/DoctorProfile";
import DepartmentDetail from "./components/detail/Departments/DepartmentDetail";
import DetailDoctor from "./components/detail/DetailDoctor";
import CommuniteesDetail from "./components/detail/Communitees/CommuniteesDetail";
import Groups from "./components/detail/Communitees/Groups";

function App() {
  return (
    <div id="wrapper">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Detail/:id" element={<Detail />} />
        <Route path="/DetailDoctor/:id" element={<DetailDoctor/>} />
        <Route path="/DepartmentDetail/:id" element={<DepartmentDetail />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/ForgotPassword" element={<ForgotPassword />} />
        <Route path="/Listing" element={<Listing />} />
        <Route path="/ListingMap" element={<ListingMap />} />
        <Route path="/Map" element={<Map />} />
        <Route path="/Settings" element={<Settings />} />
        <Route path="/MyAccount" element={<MyAccount />} />
        <Route path="/MyAccountEdit/:id" element={<MyAccountEdit />} />
        <Route path="/StoriesDetail/:id" element={<StoriesDetail />} />
        <Route path="/PackagesDetail/:id" element={<PackagesDetail />} />
        <Route path="/DoctorProfile/:id" element={<DoctorProfile/>} />
        <Route path="/CommuniteesDetail/:id" element={<CommuniteesDetail />} />
        <Route path="/Groups/" element={<Groups />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}
  

function AuthRoute({ children, redirectTo }) {
  return localStorage.getItem("data") ? (
    <Navigate to={redirectTo} />
  ) : (
    children
  );
}

function ProtectedRoute({ children, redirectTo }) {
  return localStorage.getItem("data") ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
}
export default App;