import React from "react";
import Footer from "../footer/Footer";
import Banner from "./Banner";
import CommuniteesCarousel from "./CommuniteesCarousel";
import DoctorsCarousel from "./DoctorsCarousel";
import ExploreNearby from "./ExploreNearby";
import HospitalCarousel from "./HospitalCarousel";
import PackagesCarousel from "./PackagesCarousel";

export default function Home() {
  return (
    <div>
      <Banner />
      <ExploreNearby />
      <HospitalCarousel />
      <DoctorsCarousel />
      <PackagesCarousel />
      <CommuniteesCarousel />
      <Footer />
    </div>
  );
}
