import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "@mui/material";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  TopUniversitiesCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#F7F7F7",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function ExplorEvents() {
  const classes = useStyles();
  const [active, setaAtive] = useState(0);

  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    topLists(newValue);
  };

  const history = useNavigate();
  const alert = useAlert();
  const [topUniversities, setUniversityList] = React.useState([]);
  const [typelist, setTypeList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    topLists(value);
    typeList();
  }, []);

  //method for getting recent jobs
  const topLists = (value) => {
    setLoading(true);
    axios(`Home/topLists?type_id=${value}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setUniversityList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const SaaheView = (id, type) => {
    if (type == 3) {
      history( `/DoctorProfile/${id}`);
    } else {
      history(`/Detail/${id}`);
    }
  };

  const viewalllisting = (id, type) => {
    history(`/Listing?location=${id}&type=${type}`);
  };

  //method for getting recent jobs
  const typeList = () => {
    setLoading(true);
    axios(`Home/typeList`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTypeList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#F7F7F7" }}>
        <Container maxWidth="xl"> 
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
          //    p: 1,
            }}
          >
            <Box sx={{ p: 1 }}>
           
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                color="textPrimary"
              >
            Explor Events
              </Typography>
            </Box>
          
           
          </Box>
          <Box className="TopUniversities_carousel_bg">
            <LoadingOverlay
              active={loading}
              styles={{
                overlay: (base) => ({
                  ...base,
                  background: "rgba(255, 255, 255, 1.9)",
                }),
                spinner: (base) => ({
                  ...base,
                  width: "75px",
                  "& svg circle": {
                    stroke: "#025b95",
                  },
                }),
              }}
              spinner
              text="Loading ..."
            >
              {topUniversities.length > 0 ? (
                <Box width="100%">
                  <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {topUniversities.map((value, index) => {
                      return (
                        <Box
                          className="TopUniversities_box"
                          p={1}
                        //   onClick={() => SaaheView(value.id, value.type)}
                        >
                          <Card
                            className={classes.TopUniversitiesCard}
                          >
                            <CardMedia
                              component="img"
                              height="200"
                              image={value.logo}
                              alt={value.title}
                            />

                          </Card>
                        </Box>
                      );
                    })}
                  </Carousel>
                </Box>
              ) : (
                <Box
                
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "650px",
                  }}
                >
                  <Box p={5} paddingBottom="105px" className="error">
                    <Typography variant="h6" color="primary">
                      <span className="error_text">No data</span> found.
                    </Typography>
                  </Box>
                </Box>
              )}
            </LoadingOverlay>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
