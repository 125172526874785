import React, { useContext } from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Container from "@mui/material/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "../../utils/Axios"; //import axios config
import parse from "html-react-parser";
import { Grid, IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import CallIcon from "@mui/icons-material/Call";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import ExpertDetailDialog from "./ExpertDetailDialog";
 //import DepartmentsAccordion from "./DepartmentsAccordion";
import RelatedDepartments from "./RelatedDepartments";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function DepartmentDetail(props) {
  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const [showNumber, setshowNumber] = React.useState(0);
  const [showMore, setShowMore] = React.useState(false);
  const [showButton, setshowButton] = React.useState(0);

  const params = useParams();
  const [Id, setId] = React.useState(params && params.id);

  React.useEffect(() => {
    getProfile();
  }, [Id]);

  const getProfile = () => {
    setLoading(true);
    axios(`Saahe/get_department_profile?id=${Id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setSaahe(res.data.data);
          setValue(res.data.data.rating);
          if (
            res.data.data.description &&
            res.data.data.description.length > 500
          ) {
            setshowButton(1);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const apply = (courseId, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("courseId", courseId);
      formdata.append("request_type", request_type);
      axios(`Saahe/apply`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const [ratings, setRating] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //const [raings, setRating] = React.useState([]);

  const getrating = (e, page, rowsPerPage) => {
    setLoading(true);
    axios(
      `Saahe/get_all_detail_rating?id=${Id}&page=${page}&per_page=${rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          setRating(res.data.data);
          setTotal(res.data.total);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
     
      <Box sx={{ bgcolor: "#f9f9f9", py: "1rem", pt: "5rem" }}>
        <Container>
        <Typography variant="h6" gutterBottom>  {saahe.name && saahe.name} </Typography>
          <Typography variant="body2" gutterBottom>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">
                  <Typography>Home</Typography>
                </Link>
                <Link to="#">
                  <Typography>{saahe.name && saahe.name}</Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <CardMedia
                  component="img"
                  sx={{ border: "solid 1px #f1f1f1", borderRadius: 4 }}
                  height="275"
                  Width="100%"
                  alt="Course banner"
                  image={saahe.logo}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography variant="p" color="text.secondary">
                  {saahe.description && parse(saahe.description)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </Container>

      {/* <Box sx={{ bgcolor: "background.paper" }}>
        <DepartmentsAccordion saaheId={Id} setId={setId} getrating={getrating} ratings={ratings} total={total}/>
      </Box> */} 
      <Box sx={{ bgcolor: "#fff" }}>
        {/* <RelatedDepartments /> */}
      </Box> 
      <Footer />
    </div>
  );
}
