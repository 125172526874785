import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import axios from "../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@mui/material/Tooltip";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  educationcard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#fffefe",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function DetailRelated({saaheId,setSaaheId}) {
  const classes = useStyles();
  const history = useNavigate();
  const [relatedListing, setRelatedList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    relatedList(saaheId);
  }, []);

  //method for getting recent jobs
  const relatedList = (saaheId) => {
    setLoading(true);
    axios(`Saahe/related_list?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setRelatedList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const DetailView = (id) => {
    setSaaheId(id);
    history({
      pathname: `/Detail/${id}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ pt: "48px", pb: "48px" }}>
        {relatedListing && relatedListing.length > 0 ? (
          <Container maxWidth="xl">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                p: 1,
                overflow: "hidden",
              }}
            >
              <Box sx={{ p: 1 }}>
                <Typography variant="h5" gutterBottom component="div">
                 Related Clinics
                </Typography>
              </Box>
            </Box>

            <Box className="TopUniversities_carousel_bg">
              <LoadingOverlay
                active={loading}
                styles={{
                  overlay: (base) => ({
                    ...base,
                    background: "rgba(255, 255, 255, 1.9)",
                  }),
                  spinner: (base) => ({
                    ...base,
                    width: "75px",
                    "& svg circle": {
                      stroke: "#025b95",
                    },
                  }),
                }}
                spinner
                text="Loading ..."
              >
                <Box width="100%">
                   <Carousel
                    responsive={responsive}
                    rightChevron={<KeyboardArrowRight />}
                    leftChevron={<KeyboardArrowLeft />}
                  >
                    {relatedListing &&
                      relatedListing.map((value, index) => {
                        return (
                          <Box
                            p={2}
                            className="TopUniversities_box"
                            onClick={() => DetailView(value.id)}
                          >
                            <Card
                              className={classes.educationcard}
                            >
                              <CardMedia
                                component="img"
                                height="300"
                                image={value.banner}
                                alt={value.title}
                              />
                              <Box className="cp">
                                <CardContent>
                                  <Tooltip Tooltip title={value.title}>
                                    <Typography
                                      className="text_bg"
                                      sx={{ mt: 1.5 }}
                                      gutterBottom
                                      variant="subtitle2"
                                      component="div"
                                    >
                                      {value.title}
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip Tooltip title={value.city}>
                                    <Typography
                                      className="text_bg"
                                      sx={{ mb: 1.5 }}
                                      variant="caption"
                                      color="text.secondary"
                                    >
                                      {value.city}
                                    </Typography>
                                  </Tooltip>
                                </CardContent>
                              </Box>
                            </Card>
                          </Box>
                        );
                      })}
                    </Carousel>
                </Box>
              </LoadingOverlay>
            </Box>
          </Container>
        ) : (
          ""
        )}
      </Box>
    </div>
  );
}
