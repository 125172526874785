import React, { useContext } from "react";
import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import Container from "@mui/material/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import { createTheme } from "@mui/material/styles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "../../utils/Axios"; //import axios config
import parse from "html-react-parser";
import { Grid, IconButton, Tooltip } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SchoolIcon from "@mui/icons-material/School";
import CallIcon from "@mui/icons-material/Call";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import ExpertDetailDialog from "./ExpertDetailDialog";
 import PackagesAccordion from "./PackagesAccordion";
import RelatedPackages from "./RelatedPackages";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function PackagesDetail(props) {
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const [showNumber, setshowNumber] = React.useState(0);

  const params = useParams();
  const [Id, setId] = React.useState(params && params.id);

  React.useEffect(() => {
    getPackages();
  }, [Id]);

  const getPackages = () => {
    setLoading(true);
    axios(`Saahe/get_package_info?Id=${Id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setSaahe(res.data.data);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const apply = (Id, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("Id", Id);
      formdata.append("request_type", request_type);
      formdata.append("type", 1);
      axios(`Saahe/book_package`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getPackages();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
     
      <Box sx={{ bgcolor: "#f9f9f9", py: "1rem", pt: "5rem" }}>
        <Container>
        <Typography variant="h6" gutterBottom>  {saahe.name && saahe.name} </Typography>
          <Typography variant="body2" gutterBottom>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">
                  <Typography>Home</Typography>
                </Link>
                <Link to={ '/Detail/'+saahe.hospital_id }>
                  <Typography>{saahe.hospital}</Typography>
                </Link>
                <Link to="#">
                  <Typography>{saahe.name && saahe.name}</Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <CardMedia
                  component="img"
                  sx={{ border: "solid 1px #f1f1f1", borderRadius: 4 }}
                  height="275"
                  Width="100%"
                  alt="Course banner"
                  image={saahe.banner}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Typography variant="p" color="text.secondary">
                  {saahe.description && parse(saahe.description)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} height="fit-content">
            <Box bgcolor="grey.100" borderRadius={4}>
              <Grid>
                <Grid p={1}>
                  {saahe.fees !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                            <IconButton aria-label="fees">
                              <AttachMoneyIcon />
                            </IconButton>
                            fees:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.fees}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.duration !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                            <IconButton aria-label="Duration">
                              <TimelapseIcon />
                            </IconButton>
                            Duration:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.duration}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.type_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                            <IconButton aria-label="Course Type">
                              <LibraryBooksIcon />
                            </IconButton>
                            Location:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.city}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.institution_id !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                            <IconButton aria-label="University">
                              <SchoolIcon />
                            </IconButton>
                            Available Hospital:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography variant="body2" gutterBottom>
                            {saahe.hospital}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                  {saahe.tel_no !== "" ? (
                    <Typography variant="body2" gutterBottom>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs={6} md={6}>
                        <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                            <IconButton aria-label="Teliphone">
                              <CallIcon />
                            </IconButton>
                            Teliphone:
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={6}>
                          <Typography
                            variant="body2"
                            gutterBottom
                            className="cp_bg"
                          >
                            {showNumber === 0 ? (
                              <span className="cp" onClick={() => ShowNumber()}>
                                Show number
                              </span>
                            ) : (
                              saahe.tel_no
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Typography>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#48C851"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 0px 15px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {localStorage.getItem("data") ? (
                          saahe.apply == 0 ? (
                            <Button
                              color="inherit"
                              onClick={() => apply(saahe.id, 1)}
                            >
                              Apply Package
                            </Button>
                          ) : (
                            <Button
                              color="inherit"
                              onClick={() => alert.error("Already applied!")}
                            >
                              Package Applied
                            </Button>
                          )
                        ) : (
                          <Button color="inherit">
                            Apply Package
                          </Button>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#434343"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 15px 0px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {localStorage.getItem("data") ? (
                      saahe.request == 0 ? (
                        <Button
                          color="inherit"
                          onClick={() => apply(saahe.id, 2)}
                        >
                          Request Price
                        </Button>
                      ) : (
                        <Button onClick={() => alert.error("Already Requested!")} color="inherit">Requested</Button>
                      )
                    ) : (
                      <Button
                        color="inherit">
                        Request Price
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

      {/* <Box sx={{ bgcolor: "background.paper" }}>
        <PackagesAccordion saaheId={Id} setId={setId} ratings={ratings} total={total}/>
      </Box>  */}
      <Box sx={{ bgcolor: "#fff" }}>
        <RelatedPackages saaheId={Id} setSaaheId={setId}/>
      </Box> 
      <Footer />
    </div>
  );
}
