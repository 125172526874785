import React, { useContext } from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import Container from "@mui/material/Container";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "../utils/Axios"; //import axios config
import parse from "html-react-parser";
import { Grid, Stack, IconButton, Tooltip } from "@mui/material";
import { Divider, CardMedia, createTheme, ThemeProvider } from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import StarIcon from "@mui/icons-material/Star";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
// import DoctorProfileDialog from "./DoctorProfileDialog";
// import DoctorProfileAccordion from "./DoctorProfileAccordion";
// import DoctorProfileRelated from "./DoctorProfileRelated";
// import DoctorProfileRatingReviews from "./DoctorProfileRatingReviews";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function DetailDoctor(props) {
  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const [showNumber, setshowNumber] = React.useState(0);
  const [showMore, setShowMore] = React.useState(false);
  const [showButton, setshowButton] = React.useState(0);

  const params = useParams();
  const [Id, setId] = React.useState(params && params.id);

  React.useEffect(() => {
    getProfile();
  }, [Id]);

  const getProfile = () => {
    setLoading(true);
    axios(`Saahe/get_doctor_profile?id=${Id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setSaahe(res.data.data);
          setValue(res.data.data.rating);
          if (
            res.data.data.description &&
            res.data.data.description.length > 500
          ) {
            setshowButton(1);
          }
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const apply = (Id, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("Id", Id);
      formdata.append("request_type", request_type);
      axios(`Saahe/doctor_appoinment_apply`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            getProfile();
            alert.success(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    } else {
      history.push({
        pathname: "/signin",
        state: { data: true },
      });
    }
  };

  const [ratings, setRating] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  //const [raings, setRating] = React.useState([]);

  const getrating = (e, page, rowsPerPage) => {
    setLoading(true);
    axios(
      `Saahe/get_all_detail_rating?id=${Id}&page=${page}&per_page=${rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          setRating(res.data.data);
          setTotal(res.data.total);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Box className="ExpertDetailBox">
        <Container>
          <Box sx={{ flexGrow: 1, mt: 9 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs="auto">
                <Link to="/">
                  <CardMedia
                    component={"img"}
                    sx={{
                      width: 170,
                      height: 170,
                      p: 1,
                      m: 1,
                      borderRadius: 100,
                      border: "5px solid #fff",
                      bgcolor: "#fff",
                    }}
                    src={saahe.logo}
                    alt=""
                    to="/home"
                  />
                </Link>
              </Grid>
              <Grid item xs={12} sm={5} md={4.5} lg={5.5}>
                <Grid container spacing={3} direction="column">
                  <Grid item xs={12}>
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      maxWidth="600px"
                      variant="h4"
                      gutterBottom
                      component="div"
                      color="white"
                      startIcon={<LocationOnIcon />}
                    >
                      {saahe.name && saahe.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box sx={{ color: "white", alignItems: "center" }}>
                      <IconButton aria-label="upload picture" component="span">
                        <LocationOnIcon sx={{ color: "white" }} />
                      </IconButton>
                      <Typography
                        color="white"
                        variant="standard"
                        startIcon={<LocationOnIcon />}
                      >
                        {saahe.city && saahe.city}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={5}
                md={4.5}
                lg={4}
                sx={{ flexWrap: "nowrap" }}
              >
                <Grid
                  container
                  direction="column"
                  flexWrap="nowrap"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs sx={{ color: "white" }}>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h4"
                            component="div"
                            color="white"
                          >
                            #1
                          </Typography>
                        </ThemeProvider>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          color="white"
                        >
                          Ranking
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Divider
                          sx={{
                            width: 2,
                            height: 28,
                            m: 0.5,
                            pl: 0,
                            bgcolor: "white",
                          }}
                          orientation="vertical"
                        />
                      </Grid>
                      <Grid item xs>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h4"
                            component="div"
                            color="white"
                          >
                            {saahe.experiance} yrs
                          </Typography>
                        </ThemeProvider>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          color="white"
                        >
                          Experience
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Divider
                          sx={{
                            width: 2,
                            height: 28,
                            m: 0.5,
                            bgcolor: "white",
                          }}
                          orientation="vertical"
                        />
                      </Grid>
                      <Grid item xs>
                        <ThemeProvider theme={theme}>
                          <Typography
                            variant="h4"
                            component="div"
                            color="white"
                          >
                            {saahe.no_languages}
                          </Typography>
                        </ThemeProvider>
                        <Typography
                          variant="subtitle1"
                          component="div"
                          color="white"
                        >
                          Languages
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} spacing={5} pt={3}>
                    {/* <DoctorProfileDialog
                      saaheId={Id}
                      ratingvalue={value}
                      rateType={1}
                      rating_count={saahe.rating_count}
                      getProfile={getProfile}
                      getrating={getrating}
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box sx={{ bgcolor: "#f9f9f9", py: "1rem" }}>
        <Container>
          <Typography variant="body2" gutterBottom>
            <div role="presentation" onClick={handleClick}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to="/">
                  <Typography>Home</Typography>
                </Link>
                <Link to={ '/Detail/'+saahe.hospital_id }>
                  <Typography>{saahe.hospital}</Typography>
                </Link>
                <Link to="#">
                  <Typography>{saahe.name && saahe.name}</Typography>
                </Link>
              </Breadcrumbs>
            </div>
          </Typography>
        </Container>
      </Box>

      <Container>
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={12}>
                <Typography variant="p" color="text.secondary">
                  {saahe.description && parse(saahe.description)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} height="fit-content">
            <Box bgcolor="grey.100" borderRadius={4}>
              <Grid>
                <Grid p={2}>
                <Typography variant="body2" gutterBottom>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="gray"
                          gutterBottom
                        >
                          <IconButton aria-label="Farm Value">
                            <LocalPharmacyIcon />
                          </IconButton>
                          Departments:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography variant="body2" gutterBottom>
                        {saahe.department}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="gray"
                          gutterBottom
                        >
                          <IconButton aria-label="Farm Value">
                            <LocalHospitalIcon />
                          </IconButton>
                          Specialist:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography variant="body2" gutterBottom>
                        {saahe.specilize}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="gray"
                          gutterBottom
                        >
                          <IconButton aria-label="rating">
                            <StarIcon />
                          </IconButton>
                          Type of Physician:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography variant="body2" gutterBottom>
                          {saahe.availability}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="gray"
                          gutterBottom
                        >
                          <IconButton aria-label="Farm Value">
                            <LocalPharmacyIcon />
                          </IconButton>
                          Available Hospital:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography variant="body2" gutterBottom>
                          {saahe.hospital}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="gray"
                          gutterBottom
                        >
                          <IconButton aria-label="Farm Value">
                            <LanguageIcon />
                          </IconButton>
                          Languages:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography variant="body2" gutterBottom>
                            {saahe.languages}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    <Grid
                      container
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item xs={6} md={6}>
                        <Typography
                          variant="body2"
                          fontWeight={600}
                          color="gray"
                          gutterBottom
                        >
                          <IconButton aria-label="Farm Value">
                            <LocationOnIcon />
                          </IconButton>
                          Location:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <Typography variant="body2" gutterBottom>
                        {saahe.city}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid container spacing={0}>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#48C851"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 0px 15px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Typography>
                        {localStorage.getItem("data") ? (
                          saahe.apply == 0 ? (
                            <Button
                              color="inherit"
                              onClick={() => apply(saahe.id, 1)}
                            >
                              Appoinment
                            </Button>
                          ) : (
                            <Button
                              color="inherit"
                              onClick={() => alert.error("All ready applied!")}
                            >
                              Appoinment
                            </Button>
                          )
                        ) : (
                          <Button
                            color="inherit"
                            //onClick={() => setLoginOpen(true)}
                          >
                            Appoinment
                          </Button>
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    bgcolor="#434343"
                    color="white"
                    p={1}
                    borderRadius="0px 0px 15px 0px"
                    textAlign="center"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {localStorage.getItem("data") ? (
                      saahe.request == 0 ? (
                        <Button
                          color="inherit"
                          onClick={() => apply(saahe.id, 1)}
                        >
                          Suggest an Edit
                        </Button>
                      ) : (
                        <Button color="inherit">Suggest an Edit</Button>
                      )
                    ) : (
                      <Button
                        color="inherit"
                        //onClick={() => setLoginOpen(true)}
                      >
                        Suggest an Edit
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

   
      <Box sx={{ bgcolor: "background.paper" }}>

        <Box sx={{ bgcolor: "#F7F7F7", pt: "48px", pb: "48px" }}>
            <Container>
            <ThemeProvider theme={theme}>
                <Box sx={{ width: "100%" }}>
                <Typography variant="h5"pb={2} gutterBottom color="primary" > Education Detail </Typography>
                {saahe.education_deatils && parse(saahe.education_deatils)}
                </Box>
            </ThemeProvider>
            </Container>
        </Box>

        <Box sx={{ bgcolor: "#Fff", pt: "48px", pb: "48px" }}>
            <Container>
            <ThemeProvider theme={theme}>
                <Box sx={{ width: "100%" }}>
                <Typography variant="h5"pb={2} gutterBottom color="primary" > Professional Membership </Typography>
                {saahe.professional_memberships && parse(saahe.professional_memberships)}
                </Box>
            </ThemeProvider>
            </Container>
        </Box>
      </Box>
      {/* <DoctorProfileRatingReviews getrating={getrating} total={total} ratings={ratings} getProfile={getProfile} /> */}
      <Box sx={{ bgcolor: "#fff" }}>
        {/* <DoctorProfileRelated /> */}
      </Box>
      <Footer />
    </div>
  );
}
