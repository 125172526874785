import {Card,CardMedia,Divider,Grid,Tooltip,Typography,} from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import Switch from "@mui/material/Switch";
import EditPackagesDialog from "./EditPackagesDialog";
import axios from "../../../utils/Axios"; //import axios config
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from "react-alert";
import EditPackagesDialogEdit from "./EditPackagesDialogEdit";
import DeleteConfirmDialog from "../DeleteConfirmDialog";
  
  const label = { inputProps: { "aria-label": "Switch demo" } };
  
  export default function EditPackages() {
    let params = useParams();
    const [saahe_id] = React.useState(params.id ? params.id: "");
    const alert = useAlert();
    const [packages, setPackages] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
  
    const getPackages = (saahe_id) => {
      setLoading(true);
      axios(`Saahe/get_user_packages?id=${saahe_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            setLoading(false);
          } else {
            setPackages(res.data.data);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    };
  
    React.useEffect(() => {
      getPackages(saahe_id);
    }, [saahe_id]);
  
    const handleCheck = (index, checked) => {
      let formdata = new FormData();
      formdata.append("award_id", index);
      formdata.append("status", checked);
      axios(`Saahe/update_award_status`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
            getPackages(saahe_id);
          } else {
            alert.error(res.data.message);
          }
        })
        .catch((err) => console.log(err));
    };
  
    return (
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div>
          <Grid container item spacing={3}>
            <Grid item xs={12} md={7}>
              <Typography variant="h6" fontWeight={600} color="primary">
                Packages
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={3}>
              <Typography textAlign="end">
                 <EditPackagesDialog getPackages={getPackages}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {packages.length > 0 ? (
              <Grid item xs={12}>
                {packages.map((data, index) => (
                  <Grid
                    container
                    item
                    spacing={3}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} md={2}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="70"
                          width="70"
                          alt="Sa`aeh Gallery"
                          image={data.images}
                        />
                      </Card>
                    </Grid>
                    <Grid item xs={4} md={4}>
                      <Typography>{data.title}</Typography>
                    </Grid>
                    <Grid item xs={8} md={2}>
                      <Typography>Description</Typography>
                    </Grid>
                    <Grid item xs={2} md={1}>
                      <Tooltip title="Status" aria-label="Edit Status">
                        <Switch
                          {...label}
                          onChange={(e) => handleCheck(data.id, e.target.checked)}
                          defaultChecked={data.status == 1 ? true : false}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={2} md={1}>
                       <EditPackagesDialogEdit package_id={data.id} saahe_id={saahe_id} getPackages={getPackages} />  
                    </Grid>
                    <Grid item xs={2} md={1}>
                       <DeleteConfirmDialog type="packages" id={data.id} Lists={getPackages}/> 
                    </Grid>
  
                    <Grid item xs={12} pb={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="caption" color="GrayText">
                  No News and Events found
                </Typography>
              </Grid>
            )}
          </Grid>
        </div>
      </LoadingOverlay>
    );
  }
  