import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import axios from "../../utils/Axios"; //import axios config
import { createTheme, ThemeProvider } from "@mui/material/styles";
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";


function Item(props) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "grey.100",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",
        border: "1px solid",
        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        borderRadius: 2,
        fontSize: "0.875rem",
        fontWeight: "700",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        ...sx,
      }}
      {...other}
    />
  );
}

Item.propTypes = {
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool])
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#63AB9D",
    },
    secondary: {
      main: "#434444",
    },
  },
});

export default function DoctorProfileAccordion({saaheId}) {
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState([]);

  React.useEffect(() => {
    getProfile(saaheId);
  }, []);

  const getProfile = (saaheId) => {
    setLoading(true);
    axios(`Saahe/get_list_details?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setSaahe(res.data.data[0]);
          console.log(res.data.data[0]);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
    <div style={{ width: "100%" }}>
      {saahe.custom_fields && saahe.custom_fields.map((item,index) => {
      return (
      <Box sx={{ bgcolor: item.bgcolor, pt: "48px", pb: "48px" }}>
        <Container>
          <ThemeProvider theme={theme}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="h5"pb={2} gutterBottom color="primary" > {item.title} </Typography>
              {parse(item.description)}
            </Box>
          </ThemeProvider>
        </Container>
      </Box>
       );
      })}
    </div>
    </LoadingOverlay>
  );
}
