import {
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Button,
  Tooltip,
  Container,
  Grid,
  Typography,
  CardMedia,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logo from "../../images/logo-icon.webp";
import axios from "../../utils/Axios"; //import axios config

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function FeaturedUniversities() {
  const [expanded, setExpanded] = React.useState(false);
  const [visitors, setVisitors] = React.useState([]);

  const [proexpanded, setProExpanded] = React.useState(false);

  const [skillsexpanded, setSkillsExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);

    if (expanded === "false") {
      getVisitors(5);
    } else {
      getVisitors(10);
    }
  };
  React.useEffect(() => {
    getVisitors(5);
    //   getProfileCounts();
    //  getRequests();
    //getCounts();
  }, []);

  const getVisitors = (view_all) => {
    axios(`Candidate/get_visitors_lists?view_all=${view_all}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          //alert.show(res.data.message);
        } else {
          setVisitors(res.data.data);
          console.log(res.data.data);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      {/* <Container maxWidth="xl"> */}
        <Grid item xs={12} sm={12} md={12} height="fit-content">
          <Box bgcolor="grey.100" borderRadius={4}>
            <Grid container spacing={0} p={0}>
              <Grid item xs={12}>
                <div>
                  <Box
                    display="flex"
                    className="bg_light"
                    borderRadius="8px 8px 0px 0px"
                    m={0}
                    marginBottom="0"
                    paddingBottom="0"
                    width="100%"
                  >
                    <Box width="100%">
                      <Box width="100%" display="flex">
                        <Button>Communities</Button>
                      </Box>
                    </Box>
                    <Box flexShrink={1}>
                      <Box
                        width="100%"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Tooltip title="Show all GROUPS">
                          <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    className="bg_light"
                    borderRadius="0px 0px 8px 8px"
                    //    p={2}
                    m={0}
                    marginTop="0px"
                    width="100%"
                  >
                    <Box width="100%">
                      <Box display="flex">
                        <Box className="VisitorProfile_bg">
                          <AvatarGroup>
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />{" "}
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                            <Avatar src={logo} sx={{ bgcolor: "white" }} />
                          </AvatarGroup>
                        </Box>
                      </Box>

                      <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <Box>
                          <Box width="100%">
                            <Box display="flex">
                              <Box className="VisitorProfile_bg">
                                <Box className="VisitorProfile_bg">
                                  <AvatarGroup>
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />{" "}
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                    <Avatar
                                      src={logo}
                                      sx={{ bgcolor: "white" }}
                                    />
                                  </AvatarGroup>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Collapse>
                    </Box>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      {/* </Container> */}
    </div>
  );
}
