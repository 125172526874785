import React, { useEffect, useState } from "react";
import { Box, Grid, Paper, styled } from "@mui/material/";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import axios from "../utils/Axios"; //import axios config
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import image from "../images/user.webp";
import SaveIcon from "@mui/icons-material/Save";
import Autocomplete from '@mui/material/Autocomplete';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { CardContent } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";

const useStyle = makeStyles({
  image: {
    borderRadius: 100,
  },
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function MyAccountPersonalinfo() {
  const [uploadImage, setUploadImage] = useState("");
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const [country, setCountry] = React.useState("");
  const CountryhandleChange = (event) => {
    setCountry(event.target.value);
  };

  const history = useNavigate();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [profile, setProfile] = React.useState({});
  const [gender, setgender] = React.useState("");
  const [proimage, setImage] = React.useState("");
  const [countriesss, setCountires] = useState([]);
  const [variableName, setVariableName] = useState(2);
  const [edit, setEdit] = React.useState(0);

  console.log(localStorage);
  const handleChange = (event) => {
    setgender(event.target.value);
  };

  React.useEffect(() => {
    getProfile();
    getCountries();
  }, []);

  const [value, setValue] = React.useState("");

  const handleChange2 = (event) => {
    setValue(event.target.value);
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("fullname", profile.fullname);
    formdata.append("email", profile.email);
    formdata.append("mobile", profile.mobile);
    formdata.append("gender", value);
    formdata.append("uploadImage", proimage);
    formdata.append("address", profile.address);
    formdata.append("country", profile.country);
    formdata.append("city", profile.city);
    formdata.append("zipcode", profile.zipcode);

    axios(`Profile/update_profile`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getProfile();
          setEdit(0);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  const getProfile = () => {
    setLoading(true);
    axios(`Profile/edit_profile`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          setLoading(false);
        } else {
          setLoading(false);
          setProfile(res.data.data);
          setValue(res.data.data.gender);
          setUploadImage(res.data.data.profile_image);
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = () => {
    axios(`Profile/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleEdit = (event) => {
    setEdit(1);
  };

  return (
    <div>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={8} lg={12}>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            {edit === 0 ? (
              <Grid container item spacing={0}>
                <Grid item xs={12} md={6}>
                  <Typography
                    textAlign="start"
                    gutterBottom
                    variant="h6"
                    sx={{ mb: 1.5 }}
                  >
                    My Profile
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography textAlign="end">
                    <Button
                      startIcon={<SaveIcon />}
                      onClick={handleEdit}
                      className="primaryButton"
                    >
                      Edit Profile
                    </Button>
                  </Typography>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Item>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={5}>
                            <Box
                              sx={{
                                width: 80,
                                height: 80,
                                m: 1,
                                p: 1,
                                border: "solid 1px #c0b9b9",
                                borderRadius: 50,
                              }}
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                flexDirection="column"
                              >
                                <input
                                  type="file"
                                  accept="image2/*"
                                  name="image2"
                                  id="file"
                                  onChange={loadFile}
                                  style={{ display: "none" }}
                                />

                                <img
                                  src={uploadImage ? uploadImage : image}
                                  className={classes.image}
                                  id="output"
                                  width="100%"
                                  height="100%"
                                  alt={profile.fullname}
                                />
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={7} alignSelf="center">
                            <Grid item xs={12} md={12}>
                              <Typography
                                fullWidth
                                textAlign="start"
                                fontWeight={600}
                                variant="h6"
                                gutterBottom
                              >
                                {profile.fullname}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography
                                fullWidth
                                textAlign="start"
                                variant="subtitle2"
                                gutterBottom
                              >
                                {profile.mobile}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Item>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Item>
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <Grid item xs={12} md={12} textAlign="start">
                              <Typography variant="caption" gutterBottom>
                                {" "}
                                Email{" "}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <Typography
                                fullWidth
                                textAlign="start"
                                variant="subtitle2"
                                gutterBottom
                              >
                                {profile.email}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid item xs={12} sm={6} textAlign="start">
                            <Typography variant="caption" gutterBottom>
                              {" "}
                              Address{" "}
                            </Typography>

                            <Typography
                              fullWidth
                              textAlign="start"
                              variant="subtitle2"
                              gutterBottom
                            >
                              {profile.address}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} textAlign="start">
                            <Typography variant="caption" gutterBottom>
                              {" "}
                              City{" "}
                            </Typography>
                            <Typography
                              fullWidth
                              textAlign="start"
                              variant="subtitle2"
                              gutterBottom
                            >
                              {profile.city}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} textAlign="start">
                            <Typography variant="caption" gutterBottom>
                              {" "}
                              Country{" "}
                            </Typography>
                            <Typography
                              fullWidth
                              textAlign="start"
                              variant="subtitle2"
                              gutterBottom
                            >
                              {profile.country_name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Item>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Item>
                <CardContent>
                  <Grid container item spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Typography
                        textAlign="start"
                        gutterBottom
                        variant="h6"
                        sx={{ mb: 1.5 }}
                      >
                        Edit Profile
                      </Typography>
                      <Typography
                        variant="body2"
                        textAlign="start"
                        gutterBottom
                      >
                        The information can be edited
                      </Typography>
                    </Grid>
                    {edit === 1 ? (
                      <Grid item xs={12} md={6}>
                        <Typography textAlign="end">
                          <Button
                            startIcon={<SaveIcon />}
                            onClick={submitForm}
                            className="primaryButton"
                          >
                            Save Changes
                          </Button>
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={12} md={6}>
                        <Typography textAlign="end">
                          <Button
                            startIcon={<SaveIcon />}
                            onClick={handleEdit}
                            className="primaryButton"
                          >
                            Edit
                          </Button>
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <Box sx={{ width: 80, height: 80, m: 1, p: 1 }}>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <input
                              type="file"
                              accept="image2/*"
                              name="image2"
                              id="file"
                              onChange={loadFile}
                              style={{ display: "none" }}
                            />

                            <img
                              src={uploadImage ? uploadImage : image}
                              className={classes.image}
                              id="output"
                              width="100%"
                              height="100%"
                              alt={profile.fullname}
                            />
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            padding="10px 20px"
                          >
                            <label htmlFor="file" style={{ cursor: "pointer" }}>
                              <PhotoCameraIcon />
                            </label>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={9}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="First Name"
                              value={profile.fullname}
                              onChange={(e) => {
                                setProfile({
                                  ...profile,
                                  fullname: e.target.value,
                                });
                              }}
                            />
                          </Grid>

                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              size="small"
                              placeholder="Email address"
                              value={profile.email}
                              disabled
                              onChange={(e) => {
                                setProfile({
                                  ...profile,
                                  email: e.target.value,
                                });
                              }}
                              endIcon={<EditIcon />}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              placeholder="Phone number"
                              size="small"
                              value={profile.mobile}
                              onChange={(e) => {
                                setProfile({
                                  ...profile,
                                  mobile: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              placeholder="Address"
                              size="small"
                              value={profile.address}
                              onChange={(e) => {
                                setProfile({
                                  ...profile,
                                  address: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              placeholder="Zip Code / Postal Code"
                              size="small"
                              value={profile.zipcode}
                              onChange={(e) => {
                                setProfile({
                                  ...profile,
                                  zipcode: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              placeholder="City"
                              size="small"
                              value={profile.city}
                              onChange={(e) => {
                                setProfile({
                                  ...profile,
                                  city: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FormControl sx={{ textAlign: "start" }}>
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Gender
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={value}
                                onChange={handleChange2}
                              >
                                <FormControlLabel
                                  value="1"
                                  control={<Radio />}
                                  label="Male"
                                />
                                <FormControlLabel
                                  value="2"
                                  control={<Radio />}
                                  label="Female"
                                />
                              </RadioGroup>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6}> 
                            <Autocomplete
                              size="small"
                              multiple
                              id="country-select-demo"
                              value={countriesss.filter(
                                (countriesss) =>
                                  countriesss.id == profile.country
                              )}
                              options={countriesss}
                              classes={{
                                option: classes.option,
                              }}
                              autoHighlight
                              onChange={(e, value) => {
                                setProfile({
                                  ...profile,
                                  country:
                                    value.length > 1
                                      ? value[1].id
                                      : value.length == 1
                                      ? profile.country != 0
                                        ? profile.country
                                        : value[0].id
                                      : 0,
                                });
                              }}
                              getOptionLabel={(option) => option.title}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Choose a country"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Item>
            )}
          </LoadingOverlay>
        </Grid>
      </Grid>
    </div>
  );
}
