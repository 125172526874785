import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import axios from "../utils/Axios"; //import axios config
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import { makeStyles } from "@material-ui/core/styles";
import { CardContent } from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const useStyles = makeStyles((theme) => ({
  MeetCareerExpertsCard: (hover) => ({
    width: "100%",
    height: "100%",
    background: "#FFF",
    borderRadius: "10px",
    boxShadow: "0px 0px 10px #00000029",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 0px 10px #00000069",
    },
  }),
}));

export default function PackagesCarousel() {
  const classes = useStyles();
  const [active, setaAtive] = useState(0);

  const history = useNavigate();
  const [packages, setPackageList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    packgeList();
  }, []);

  const packgeList = () => {
    setLoading(true);
    axios(`Home/getPackages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setPackageList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const PackagesView = (id) => {
    history(`/PackagesDetail/${id}`);
  };

  const viewallpackages = (id, institute_type) => {
    history({
      pathname: `/Listing`,
      search: `?location=${id}&institute_type=${institute_type}`,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Box className="caro_bg" sx={{ bgcolor: "#F7F7F7", pt: "48px", pb: "48px" }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              p: 1,
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: 1 }}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="#1F3A57"
              >
               Packages & Campaigns
              </Typography>
            </Box>
            <Box sx={{ p: 1 }}>
              <Divider
                color="white"
                sx={{ height: 28, m: 0.5 }}
                orientation="vertical"
              />
            </Box>
            <Box className="cp_bg" sx={{ p: 1, flexGrow: 1 }}>
              <Typography
                className="cp"
                variant="body2"
                color="InactiveCaptionText"
                gutterBottom
                component="div"
                onClick={() => viewallpackages(0, 3)}
              >
                View All
              </Typography>
            </Box>
          </Box>

          <Box width="100%" className="TopUniversities_carousel_bg">
            <Carousel
              responsive={responsive}
              rightChevron={<KeyboardArrowRight />}
              leftChevron={<KeyboardArrowLeft />}
            >
              {packages && packages.map((value, index) => {
                  return (
                    <Box
                      p={2}
                      className="TopUniversities_box"
                      onClick={() => PackagesView(value.id, 3)}
                    >
                      <Card className={classes.MeetCareerExpertsCard}>
                        <CardMedia
                          component="img"
                          height="300"
                          image={value.logo}
                          alt=" Business Education Trainer"
                        />
                        <Box className="cp" bgcolor="#fff" width="100%">
                          <CardContent>
                            <Tooltip Tooltip title={value.title}>
                              <Typography
                                sx={{ mt: 1.5 }}
                                gutterBottom
                                variant="subtitle2"
                                component="div"
                                className="text_bg"
                              >
                                {value.title}
                              </Typography>
                            </Tooltip>
                            <Tooltip Tooltip title={value.city}>
                              <Typography
                                sx={{ mb: 1.5 }}
                                variant="caption"
                                color="text.secondary"
                                className="text_bg"
                              >
                                {value.city}
                              </Typography>
                            </Tooltip>
                          </CardContent>
                        </Box>
                      </Card>
                    </Box>
                  );
                })}
            </Carousel>
          </Box>
        </Container>
      </Box>
    </div>
  );
}
