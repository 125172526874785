import React, { useState } from "react";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, Grid, Paper, Typography, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import image2 from "../../../images/logo.webp";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "../../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";
import EditIcon from "@mui/icons-material/Edit";

const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});
export default function MyAccountEditAwardsDialogEdit({ award_id,saahe_id, getAwards }) {
  const [status, setStatus] = React.useState("");

  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const alert = useAlert();
  const [value, setValue] = React.useState(new Date());
  const [image, setImage] = React.useState("");
  const [awardsName, setAwards_name] = React.useState("");
  const [awardsDescription, setDescription] = React.useState("");
  const [edit, setedits] = React.useState({});

  const [uploadImage, setUploadImage] = useState("");
  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      console.log(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);

    axios(`Saahe/get_awards_info?id=${award_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
          alert.error(res.data.message);
        } else {
          setedits(res.data.data[0]);
          setValue(res.data.data[0].awd_date);
          setUploadImage(res.data.data[0].images);
          setStatus(res.data.data[0].status);
        }
      })
      .catch((err) => console.log(err));

  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    console.log(value);
    formdata.append("award_id", award_id);
    formdata.append("awardsName", edit.title);
    formdata.append("logo", image);
    formdata.append("awardsDescription", edit.description);
    formdata.append("awdate", value);
    formdata.append("status", status);
    axios(`Saahe/update_awards`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getAwards(saahe_id);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Tooltip onClick={handleClickOpen} title="Edit" aria-label="Edit">
        <EditIcon />
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Edit Awards and Accreditations</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                placeholder="Title"
                variant="outlined"
                value={edit.title}
                onChange={(e) => {
                  setedits({ ...edit, title: e.target.value });
                }}
              />
               <FormControl fullWidth size="small" sx={{ mt: 3 }}> 
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={StatushandleChange}
                >
                  <MenuItem value={1}>Enable</MenuItem>
                  <MenuItem value={2}>Disable</MenuItem>
                </Select>
              </FormControl>
            </Grid>
           
          
            <Grid item xs={12} md={6} lg={6}>
              <Paper >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">Thumbnail</Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                placeholder="Description"
                multiline
                rows={4}
                value={edit.description}
                onChange={(e) => {
                  setedits({ ...edit, description: e.target.value });
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
