import React, { useState } from "react";
import {Box,Typography,TextField,Autocomplete,Paper,Tooltip,Divider,MenuItem,Button,IconButton,
  Grid,InputLabel,FormControl,Select} from "@mui/material/";
import PropTypes from "prop-types";
import axios from "../../utils/Axios"; //import axios config
import { makeStyles } from "@material-ui/core/styles";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Link, useNavigate ,useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import logo_skeleton from "../../images/skeleton/logo_skeleton.webp";
import LoadingOverlay from "react-loading-overlay";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyle = makeStyles({
  image2: {
    borderRadius: 10,
    maxWidth: "100%",
  },
  image3: {
    borderRadius: 10,
    maxWidth: "100%",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function MyAccountEditBasicDetails({setSahheId}) {
  
  let params = useParams();

  const [specializes, setSpecializes] = React.useState([""]);
  const [languages, setLanguages] = React.useState([""]);
  const [availability, setAvailability] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [specialize, setSpecialize] = React.useState([""]);
  const [language, setLanguage] = React.useState([""]);
  const [saahe_id] = React.useState(params.id ? params.id: "");
  

  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  var fileload2 = (event) => {
    console.log(event.target.files);
    if (event.target.files) {
      setUploadBanner(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const [status, setStatus] = React.useState("");
  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  const history = useNavigate();
  const alert = useAlert();
  const [loading, setLoading] = React.useState(false);
  const [Name, setName] = React.useState("");
  const [Mobile, setMobile] = React.useState("");
  const [types, setTypes] = React.useState([]);
  const [type, setType] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [Address, setAddress] = React.useState("");
  const [cities, setCities] = React.useState([]);
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [zipcode, setZipcode] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [location, setLocation] = React.useState("");
  const [staff, setStaff] = React.useState("");
  const [latitude, setLatitude] = React.useState("");
  const [map, setMap] = React.useState("");
  const [longitude, setLongitude] = React.useState("");
  const [uploadImage, setUploadImage] = React.useState("");
  const [bannerImage, setUploadBanner] = React.useState("");
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [content, setContent] = useState("");
  const [countriesss, setCountires] = useState([]);
  const [fees, setFees] = React.useState("");
  const [experience, setExperience] = React.useState("");
  const [available, setAvailable] = React.useState("");
  const [yearbuild, setYearbuilt] = React.useState("");
  

  React.useEffect(() => {
    editPost();
    getType();
    getCities();
    getCountries();
    getLanguages();
    getSpecializes();
  }, []);

  const editPost = () => {
    setLoading(true);
    axios(`Saahe/get_own_organization_profile?Id=${saahe_id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.error === true) {
          history.push({
            pathname: "/",
          });
        } else {
          setLoading(false);
          setName(res.data.data[0].name);
          setType(res.data.data[0].type_id);
          setSahheId(res.data.data[0].type_id);
          setMobile(res.data.data[0].tel_no);
          setEmail(res.data.data[0].email);
          setAddress(res.data.data[0].address_1);
          setCity(res.data.data[0].city_id);
          setCountry(res.data.data[0].country);
          setZipcode(res.data.data[0].zipcode);
          setWebsite(res.data.data[0].website);
          setLocation(res.data.data[0].map_link);
          setStaff(res.data.data[0].no_of_staff);
          setLatitude(res.data.data[0].latitude);
          setLongitude(res.data.data[0].longitude);
          setMap(res.data.data[0].maplink);
          setImage(res.data.data[0].logo);
          setBanner(res.data.data[0].banner);
          setContent(res.data.data[0].description);
          setStatus(res.data.data[0].status);
          setFees(res.data.data[0].fees);
          setExperience(res.data.data[0].experiance);
          setAvailability(res.data.data[0].availability);
          setYearbuilt(res.data.data[0].yearbuild);
          setSpecialize(res.data.data[0].specilizes);
          setLanguage(res.data.data[0].languages);
          setGender(res.data.data[0].gender);
          setAvailable(res.data.data[0].hospital);
        }
      })
      .catch((err) => console.log(err));
  };

  const getType = () => {
    axios(`Saahe/get_types`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setTypes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCities = () => {
    axios(`Saahe/getCities`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCities(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const getCountries = () => {
    axios(`Saahe/getCountries`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setCountires(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getSpecializes = () => {
    axios(`Saahe/get_specializes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setSpecializes(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };


  const getLanguages = () => {
    axios(`Saahe/get_languages`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error === true) {
        } else {
          if (!res.data.status) {
          } else {
            setLanguages(res.data.data);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const setSpecializeSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setSpecialize(arr);
  };


  const setLanguageSelect = async (val) => {
    let arr = [];
    await val.map((value, index) => {
      arr.push(value.id);
    });
    setLanguage(arr);
  };

  const availabilityChange = (event) => {
    setAvailability(event.target.value);
  };

  const GenderChange = (event) => {
    setGender(event.target.value);
  };

  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("id", saahe_id);
    formdata.append("Name", Name);
    formdata.append("type", type);
    formdata.append("Email", Email);
    formdata.append("Mobile", Mobile);
    formdata.append("Address", Address);
    formdata.append("city", city);
    formdata.append("staffs", staff);
    formdata.append("country", country);
    formdata.append("zipcode", zipcode);
    formdata.append("desc", content);
    formdata.append("location", location);
    formdata.append("website",website);
    formdata.append("map", map);
    formdata.append("fees", fees);
    formdata.append("experience", experience);
    formdata.append("uploadImage", image);
    formdata.append("bannerImage", banner);
    formdata.append("status", status);
    if(type!=3)
    {
    formdata.append("latitude", latitude);
    formdata.append("longitude", longitude);
    axios(`Saahe/update_info`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
    }
    else
    {
      formdata.append("specializes", JSON.stringify(specialize));
      formdata.append("languages", JSON.stringify(language));
      formdata.append("gender", gender);
      formdata.append("availability", availability);
      formdata.append("hospital", available);
      axios(`Saahe/update_expert`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.status) {
            alert.success(res.data.message);
          } else {
            alert.error(res.data.message);

          }
        })
        .catch((err) => console.log(err));
    }
  };

  let inst_image;
  if (image !== "") {
    inst_image = image;
  } else {
    inst_image = logo_skeleton;
  }

  let inst_banner;
  if (banner !== "") {
    inst_banner = banner;
  } else {
    inst_banner = logo_skeleton;
  }

  
  return type !=3 ? (
    <div>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Grid container item spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h6" color="GaryText">
              Basic Details
            </Typography>
            <Typography variant="body2" textAlign="start" gutterBottom>
              The information can be edited
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Link to="/MyAccount">
              <Typography textAlign="end">
                <Tooltip title="MyAccount">
                  <IconButton>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Link>{" "}
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography textAlign="end">
              <Button variant="contained" color="primary" onClick={submitForm}>
                Save
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Medical Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={types}
                getOptionLabel={(option) => option.name}
                value={types.filter(
                  (types) => types.id == type
                )}
                onChange={(e, value) => {
                  setType(
                    value.length > 1
                      ? value[1].id
                      : value.length == 1
                      ? type != 0
                        ? type
                        : value[0].id
                      : 0
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Type of Medical" />
                )}
              />
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Sub Title"
              id="outlined-start-adornment"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Contact Number"
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Address"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Address Line 2"
              id="outlined-start-adornment"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo2"
                options={cities}
                getOptionLabel={(option) => option.name}
                value={cities.filter(
                  (cities) => cities.id == city
                )}
                onChange={(e, value) => {
                  setCity(
                    value.length > 1
                      ? value[1].id
                      : value.length == 1
                      ? city != 0
                        ? city
                        : value[0].id
                      : 0
                  );
                }}
                autoHighlight
                renderInput={(params) => <TextField {...params} label="City" />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Zip Code / Postal Code"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="country-select-demo"
                value={countriesss.filter(
                  (countriesss) => countriesss.id == country
                )}
                options={countriesss}
                classes={{
                  option: classes.option,
                }}
                onChange={(e, value) => {
                  setCountry(
                    value.length > 1
                      ? value[1].id
                      : value.length == 1
                      ? country != 0
                        ? country
                        : value[0].id
                      : 0
                  );
                }}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose a country"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Map Link"
              id="outlined-start-adornment"
              value={map}
              onChange={(e) => setMap(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Longitude"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="No of Staffs"
              value={staff}
              onChange={(e) => setStaff(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Built Year"
              value={yearbuild}
              onChange={(e) => setYearbuilt(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={StatushandleChange}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>In-Active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {" "}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper style={{ width: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file"
                  onChange={loadFile}
                  style={{ display: "none" }}
                />
                <Typography variant="caption">Logo or Profile image</Typography>

                <img
                  src={uploadImage ? uploadImage : inst_image}
                  className={classes.image2}
                  alt="logo"
                  id="output"
                  width="auto"
                  maxWidth="100%"
                  height="135px"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" padding="5px 20px">
                <label htmlFor="file" style={{ cursor: "pointer" }}>
                  <PhotoCameraIcon />
                </label>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <Paper style={{ width: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file2"
                  onChange={fileload2}
                  style={{ display: "none" }}
                />
                <Typography variant="caption">Banner </Typography>

                <img
                  src={bannerImage ? bannerImage : inst_banner}
                  className={classes.image2}
                  id="output2"
                  width="auto"
                  maxWidth="100%"
                  height="135px"
                  alt="test"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" padding="5px 20px">
                <label htmlFor="file2" style={{ cursor: "pointer" }}>
                  <PhotoCameraIcon />
                </label>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </LoadingOverlay>
    </div>
    ) : (

      <div>
      <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <Grid container item spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h6" color="GaryText">
              Basic Doctor Details
            </Typography>
            <Typography variant="body2" textAlign="start" gutterBottom>
              The information can be edited
            </Typography>
          </Grid>
          <Grid item xs={12} md={1}>
            <Link to="/MyAccount">
              <Typography textAlign="end">
                <Tooltip title="MyAccount">
                  <IconButton>
                    <ArrowBackIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Link>{" "}
          </Grid>
          <Grid item xs={12} md={1}>
            <Typography textAlign="end">
              <Button variant="contained" color="primary" onClick={submitForm}>
                Save
              </Button>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Doctor Name"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={types}
                getOptionLabel={(option) => option.name}
                value={types.filter(
                  (types) => types.id == type
                )}
                onChange={(e, value) => {
                  setType(
                    value.length > 1
                      ? value[1].id
                      : value.length == 1
                      ? type != 0
                        ? type
                        : value[0].id
                      : 0
                  );
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Type of Medical" />
                )}
              />
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Sub Title"
              id="outlined-start-adornment"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Contact Number"
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={specializes}
                getOptionLabel={(option) => option.name}
                value={specializes.filter(
                  (specializes) => specialize && specialize.includes(specializes.id)
                )}
                onChange={(e, value) => {
                  setSpecializeSelect(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Specializes" />
                )}
              />
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo"
                options={languages}
                getOptionLabel={(option) => option.name}
                value={languages.filter(
                  (languages) => language && language.includes(languages.id)
                )}
                onChange={(e, value) => {
                  setLanguageSelect(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Languages" />
                )}
              />
            </FormControl>{" "}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Status"
                onChange={GenderChange}
              >
                <MenuItem value={1}>Male</MenuItem>
                <MenuItem value={2}>Female</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Availability</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={availability}
                label="Status"
                onChange={availabilityChange}
              >
                <MenuItem value={1}>Full Time</MenuItem>
                <MenuItem value={2}>Part Time</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="combo-box-demo2"
                options={cities}
                getOptionLabel={(option) => option.name}
                value={cities.filter(
                  (cities) => cities.id == city
                )}
                onChange={(e, value) => {
                  setCity(
                    value.length > 1
                      ? value[1].id
                      : value.length == 1
                      ? city != 0
                        ? city
                        : value[0].id
                      : 0
                  );
                }}
                autoHighlight
                renderInput={(params) => <TextField {...params} label="City" />}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Address"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Address Line 2"
              id="outlined-start-adornment"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Zip Code / Postal Code"
              value={zipcode}
              onChange={(e) => setZipcode(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <Autocomplete
                size="small"
                multiple
                id="country-select-demo"
                value={countriesss.filter(
                  (countriesss) => countriesss.id == country
                )}
                options={countriesss}
                classes={{
                  option: classes.option,
                }}
                onChange={(e, value) => {
                  setCountry(
                    value.length > 1
                      ? value[1].id
                      : value.length == 1
                      ? country != 0
                        ? country
                        : value[0].id
                      : 0
                  );
                }}
                autoHighlight
                getOptionLabel={(option) => option.title}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nationality"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Map Link"
              id="outlined-start-adornment"
              value={map}
              onChange={(e) => setMap(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <TextField
              fullWidth
              size="small"
              label="Latitude"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              required
              label="Available Hospitals"
              variant="outlined"
              value={available}
              onChange={(e) =>setAvailable(e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              label="Experience"
              variant="outlined"
              value={experience}
              onChange={(e) =>setExperience(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              size="small"
              fullWidth
              required
              label="Fees"
              variant="outlined"
              value={fees}
              onChange={(e) =>setFees(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={StatushandleChange}
              >
                <MenuItem value={1}>Active</MenuItem>
                <MenuItem value={2}>In-Active</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            {" "}
          </Grid>
          <Grid item xs={12} md={3} lg={3}>
            <Paper style={{ width: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file"
                  onChange={loadFile}
                  style={{ display: "none" }}
                />
                <Typography variant="caption">Logo or Profile image</Typography>

                <img
                  src={uploadImage ? uploadImage : inst_image}
                  className={classes.image2}
                  alt="logo"
                  id="output"
                  width="auto"
                  maxWidth="100%"
                  height="135px"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" padding="5px 20px">
                <label htmlFor="file" style={{ cursor: "pointer" }}>
                  <PhotoCameraIcon />
                </label>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} lg={9}>
            <Paper style={{ width: "100%" }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
              >
                <input
                  type="file"
                  accept="image/*"
                  name="image"
                  id="file2"
                  onChange={fileload2}
                  style={{ display: "none" }}
                />
                <Typography variant="caption">Banner </Typography>

                <img
                  src={bannerImage ? bannerImage : inst_banner}
                  className={classes.image2}
                  id="output2"
                  width="auto"
                  maxWidth="100%"
                  height="135px"
                  alt="test"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" padding="5px 20px">
                <label htmlFor="file2" style={{ cursor: "pointer" }}>
                  <PhotoCameraIcon />
                </label>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </LoadingOverlay>
    </div>
    );
}
