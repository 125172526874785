import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import axios from "../utils/Axios"; //import axios config
import { useNavigate } from "react-router-dom";
import DeleteConfirmDialog from "./DeleteConfirmDialog";
import LoadingOverlay from "react-loading-overlay";

export default function MyAccountManageListingList() {
  const history = useNavigate();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [experts, setExpertsList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    //topCoursesList();
    manageList();
  }, [page, rowsPerPage]);

  const manageList = () => {
    setLoading(true);
    axios(
      `Saahe/user_organizations?page=${page}&per_page=${rowsPerPage}&delay=1`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          setLoading(false);
          console.log(res.data.message);
        } else {
          setLoading(false);
          setTotal(res.data.total);
          setExpertsList(res.data.data); //to set response data to state
        }
      })
      .catch((err) => console.log(err));
  };

  const getEdit = (id,type) => {
    history(`/MyAccountEdit/${id}`);
  };

  return (
    <div>
      {experts.length > 0 ? (
        <LoadingOverlay
        active={loading}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "rgba(255, 255, 255, 1.9)",
          }),
          spinner: (base) => ({
            ...base,
            width: "75px",
            "& svg circle": {
              stroke: "#025b95",
            },
          }),
        }}
        spinner
        text="Loading ..."
      >
        <div>
             {experts && experts.map((value, index) => {
              return (
              <Box sx={{ display: "flex", p: 1 }}>
                <Box>
                  <ListItemAvatar>
                    <Avatar src={value.logo} alt={value.title} />
                  </ListItemAvatar>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <ListItemText primary={value.title} />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <ListItemText primary={value.type_list} />
                </Box>
              
                <Box>
                  <ListItem
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => getEdit(value.id,value.type)}
                      >
                        <EditIcon />
                      </IconButton>
                    }
                  ></ListItem>
                </Box>
                <Box>
                  <DeleteConfirmDialog type={value.type_list} id={value.id} Lists={manageList}/>
                </Box>
              </Box>
              );
           })}
           </div>
            </LoadingOverlay>
            ) : (
              <div>
              <Box className="EducationProgramsContainer">
              <Box p={1} className="EducationDetailPrograms_bg cp">
                <Box p={1}>
                    No Lists Found
                </Box>
              </Box>
            </Box>
            </div>
             )}
      {/* <Box p={2} display="flex" justifyContent="center">
        <Stack spacing={2}>
          <Pagination count={total} page={page} onChange={handleChange} />
        </Stack>
      </Box> */}
    </div>
  );
}
