import React from "react";
import Footer from "../footer/Footer";
import Header from "../header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlert } from "react-alert";
import {Container,Grid,Box,Button,IconButton,Tooltip,Stack,Breadcrumbs,Typography} from "@mui/material";
import { Divider, CardMedia, createTheme, ThemeProvider } from "@mui/material";
import axios from "../utils/Axios"; //import axios config
import parse from "html-react-parser";
import LoadingOverlay from "react-loading-overlay";
import LanguageIcon from "@mui/icons-material/Language";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CallIcon from "@mui/icons-material/Call";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import StarIcon from "@mui/icons-material/Star";
import GroupsIcon from "@mui/icons-material/Groups";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import DetailBanner from "./DetailBanner";
import DetailRelated from "./DetailRelated";
import DetailAccordion from "./DetailAccordion";
import ReviewRatingDialog from "./ReviewRatingDialog";
import Departments from "./Departments";
import Doctors from "./Doctors";
import DetailNearby from "./DetailNearby";
import PackagesCarousel from "./DetailPackagesCarousel";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "2rem",
  "@media (min-width:600px)": {
    fontSize: "2rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "3rem",
    fontWeight: "600",
  },
};
theme.typography.h4 = {
  fontSize: "1.5rem",
  fontWeight: "400",
  "@media (min-width:600px)": {
    fontSize: "2rem",
    fontWeight: "200",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "2.3rem",
    fontWeight: "400",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.3rem",
    fontWeight: "400",
  },
};

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function Detail(props) {
  const [value, setValue] = React.useState(0);
  const alert = useAlert();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [saahe, setSaahe] = React.useState({});
  const params = useParams();
  const [saaheId, setSaaheId] = React.useState(params && params.id);
  const [showMore, setShowMore] = React.useState(false);
  const [ratings, setRating] = React.useState([]);
  const [showButton, setshowButton] = React.useState(0);
  const[count,setCount]=React.useState(0);
  React.useEffect(() => {
    setSaaheId(params && params.id ? params.id : "");
  }, [params]);
  const [showNumber, setshowNumber] = React.useState(0);
  React.useEffect(() => {
    getDetailProfile();
  }, [saaheId]);
  const getDetailProfile = () => {
    setLoading(true);
    axios(`Saahe/get_list_details?id=${saaheId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          window.scrollTo(0, 0);
          setSaahe(res.data.data[0]);
          if (
            res.data.data[0].description &&
            res.data.data[0].description.length > 500
          ) {
            setshowButton(1);
          }
          setLoading(false);
          setValue(res.data.data[0].rating);
        }
      })
      .catch((err) => console.log(err));
  };

  //Favoraite job function start
  const admission = (Id, request_type) => {
    if (JSON.parse(localStorage.getItem("data"))) {
      let formdata = new FormData();
      formdata.append("id", Id);
      formdata.append("request_type", request_type);
      axios(`Saahe/addadmission`, {
        method: "POST",
        data: formdata,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      })
        .then((res) => {
          if (res.data.error) {
            alert.error(res.data.message);
          } else {
            alert.success(res.data.message);
            getDetailProfile();
          }
        })
        .catch((err) => console.log(err));
    } else {
      history("/Login");
    }
  };

  const ShowNumber = () => {
    setshowNumber(1);
  };

  const [total, setTotal] = React.useState(0);

  const getrating = (e, page, rowsPerPage) => {
    setLoading(true);
    axios(
      `Saahe/get_all_detail_rating?id=${saaheId}&page=${page}&per_page=${rowsPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": JSON.parse(localStorage.getItem("data")),
        },
      }
    )
      .then((res) => {
        if (res.data.error) {
          alert.show(res.data.message);
          setLoading(false);
        } else {
          setLoading(false);
          setRating(res.data.data);
          setTotal(res.data.total);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: "100%" }}>
      <Header />
      <Box sx={{ bgcolor: "#63AB9D" }}>
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          <Container>
            <Box sx={{ flexGrow: 1, my: 5, mt: 9 }}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs="auto">
                  <Link to="/">
                    <CardMedia
                      component={"img"}
                      sx={{
                        width: 170,
                        height: 170,
                        p: 1,
                        m: 1,
                        borderRadius: 100,
                        border: "5px solid #fff",
                        bgcolor: "#fff",
                      }}
                      src={saahe.logo}
                      alt=""
                      to="/home"
                    />
                  </Link>
                </Grid>
                <Grid item xs={12} sm={5} md={4.5} lg={5.5}>
                  <Grid container spacing={3} direction="column">
                    <Grid item xs={12}>
                      <Typography
                        textOverflow="ellipsis"
                        overflow="hidden"
                        maxWidth="600px"
                        variant="h4"
                        gutterBottom
                        component="div"
                        color="white"
                        startIcon={<LocationOnIcon />}
                      >
                        {saahe.name && saahe.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ color: "white", alignItems: "center" }}>
                        <IconButton
                          aria-label="upload picture"
                          component="span"
                        >
                          <LocationOnIcon sx={{ color: "white" }} />
                        </IconButton>
                        <Typography
                          color="white"
                          variant="standard"
                          startIcon={<LocationOnIcon />}
                        >
                          {saahe.city && saahe.city}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={4.5}
                  lg={4}
                  sx={{ flexWrap: "nowrap" }}
                >
                  <Grid
                    container
                    direction="column"
                    flexWrap="nowrap"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid item xs sx={{ color: "white" }}>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h4"
                              component="div"
                              color="white"
                            >
                              #1
                            </Typography>
                          </ThemeProvider>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            color="white"
                          >
                            Ranking
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Divider
                            sx={{
                              width: 2,
                              height: 28,
                              m: 0.5,
                              pl: 0,
                              bgcolor: "white",
                            }}
                            orientation="vertical"
                          />
                        </Grid>
                        <Grid item xs>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h4"
                              component="div"
                              color="white"
                            >
                             {count}
                            </Typography>
                          </ThemeProvider>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            color="white"
                          >
                            Departments
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Divider
                            sx={{
                              width: 2,
                              height: 28,
                              m: 0.5,
                              bgcolor: "white",
                            }}
                            orientation="vertical"
                          />
                        </Grid>
                        <Grid item xs>
                          <ThemeProvider theme={theme}>
                            <Typography
                              variant="h4"
                              component="div"
                              color="white"
                            >
                              {saahe.doctors_count}
                            </Typography>
                          </ThemeProvider>
                          <Typography
                            variant="subtitle1"
                            component="div"
                            color="white"
                          >
                            Doctors
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} spacing={5} pt={3}>
                      <ReviewRatingDialog saaheId={saaheId} ratingvalue={value} rateType={1} rating_count={2} getProfile={getDetailProfile} getrating={getrating}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Container>
          <Box sx={{ bgcolor: "#f9f9f9", py: "1rem", pt: "1rem" }}>
            <Container>
              <Typography variant="body2" gutterBottom>
                <div role="presentation" onClick={handleClick}>
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/">
                      <Typography>Home</Typography>
                    </Link>
                    <Link to="/Listing?location=0&type=1">
                      <Typography>Hospitals </Typography>
                    </Link>
                    <Typography color="#572672">
                      {saahe.name && saahe.name}
                    </Typography>
                  </Breadcrumbs>
                </div>
              </Typography>
            </Container>
          </Box>
        </LoadingOverlay>
      </Box>

      <Box sx={{ bgcolor: "background.paper" }}>
        <Container>
          <LoadingOverlay
            active={loading}
            styles={{
              overlay: (base) => ({
                ...base,
                background: "rgba(255, 255, 255, 1.9)",
              }),
              spinner: (base) => ({
                ...base,
                width: "75px",
                "& svg circle": {
                  stroke: "#025b95",
                },
              }),
            }}
            spinner
            text="Loading ..."
          >
            <Grid container spacing={2} py={3}>
              <Grid item xs={12} sm={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <DetailBanner image={saahe.banner} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="p" color="text.secondary">
                      {showMore
                        ? saahe.description && parse(saahe.description)
                        : `${
                            saahe.short_description && saahe.short_description
                          }`}
                      {showButton === 1 ? (
                        <Typography variant="p" color="text.secondary">
                          {showMore ? "" : ".."}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Typography>{" "}
                  </Grid>
                  <Grid item xs={12}>
                    {showButton === 1 ? (
                      <Button
                        className="btn"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Show less" : "Show more"}
                      </Button>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <Grid item xs={12} sm={12} md={12} height="fit-content">
                  <Box bgcolor="grey.100" borderRadius={4}>
                    <Grid>
                      <Grid p={1}>
                        <Typography variant="body2" gutterBottom>
                          <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={6} md={6}>
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                                <IconButton aria-label="Farm Value">
                                  <LocalHospitalIcon />
                                </IconButton>
                                Specialty:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body2" gutterBottom>
                                {saahe.type}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>

                        {saahe.rating !== "" ? (
                          <Typography variant="body2" gutterBottom>
                            <Grid
                              container
                              spacing={0}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={6} md={6}>
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                                  <IconButton aria-label="rating">
                                    <StarIcon />
                                  </IconButton>
                                  Rating:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Typography variant="body2" gutterBottom>
                                  {saahe.rating} Stars{" "}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        ) : (
                          ""
                        )}

                        {saahe.no_of_staffs !== "" ? (
                        <Typography variant="body2" gutterBottom>
                          <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid item xs={6} md={6}>
                              <Typography
                                variant="body2"
                                fontWeight={600}
                                color="gray"
                                gutterBottom
                              >
                                <IconButton aria-label="Farm Value">
                                  <GroupsIcon />
                                </IconButton>
                                Number of Staff:
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={6}>
                              <Typography variant="body2" gutterBottom>
                              {saahe.no_of_staffs}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Typography>
                        ) : (
                          ""
                        )}

                        {saahe.tel_no !== "" ? (
                          <Typography variant="body2" gutterBottom>
                            <Grid
                              container
                              spacing={0}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={6} md={6}>
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                                  <IconButton aria-label="Teliphone">
                                    <CallIcon />
                                  </IconButton>
                                  Telephone:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Typography variant="body2" gutterBottom>
                                  {showNumber === 0 ? (
                                    <span
                                      className="cp"
                                      onClick={() => ShowNumber()}
                                    >
                                      Show number
                                    </span>
                                  ) : (
                                    saahe.tel_no
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        ) : (
                          ""
                        )}

                        {saahe.address1 !== "" ? (
                          <Typography variant="body2" gutterBottom>
                            <Grid
                              container
                              spacing={0}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={6} md={6}>
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                                  <IconButton aria-label="address">
                                    <LocationOnIcon />
                                  </IconButton>
                                  Address:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Typography variant="body2" gutterBottom>
                                  {saahe.address_1} {saahe.address_2}{" "}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Typography>
                        ) : (
                          ""
                        )}

                        {saahe.website !== "" ? (
                          <Typography
                            className="cp_bg"
                            variant="body2"
                            gutterBottom
                          >
                            <Grid
                              container
                              spacing={0}
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Grid item xs={6} md={6}>
                                <Typography
                                  variant="body2"
                                  fontWeight={600}
                                  color="gray"
                                  gutterBottom
                                >
                                  <IconButton aria-label="Website">
                                    <LanguageIcon />
                                  </IconButton>
                                  Website:
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={6}>
                                <Typography variant="body2" gutterBottom>
                                  <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={saahe.website}
                                  >
                                    {saahe.website}
                                  </a>
                                </Typography>
                              </Grid>
                            </Grid>

                            <Stack direction="row" spacing={1}>
                              <Link target="_blank" to="">
                                <Tooltip title="Instagram" placement="top">
                                  <IconButton aria-label="Instagram saaeh">
                                    <InstagramIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              <Link target="_blank" to="">
                                <Tooltip title="Twitter" placement="top">
                                  <IconButton aria-label="Twitter saaeh">
                                    <TwitterIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              <Link target="_blank" to="">
                                <Tooltip title="Facebook" placement="top">
                                  <IconButton aria-label="Facebook saaeh">
                                    <FacebookIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              <Link target="_blank" to="">
                                <Tooltip title="LinkedIn" placement="top">
                                  <IconButton aria-label="LinkedIn saaeh">
                                    <LinkedInIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                              <Link target="_blank" to="">
                                <Tooltip title="YouTube" placement="top">
                                  <IconButton aria-label="YouTube saaeh">
                                    <YouTubeIcon />
                                  </IconButton>
                                </Tooltip>
                              </Link>
                            </Stack>
                          </Typography>
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid
                          item
                          xs={6}
                          bgcolor="#48C851"
                          color="white"
                          p={1}
                          borderRadius="0px 0px 0px 15px"
                          textAlign="center"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item xs={12}>
                            {saahe.apply == 0 ? (
                              <Button
                                color="inherit"
                                onClick={() => admission(saahe.id, 1,1)}
                              >
                                Appoinment
                              </Button>
                            ) : (
                              <Button
                                color="inherit"
                                onClick={() =>
                                  alert.error("All ready applied!")
                                }
                              >
                                Appoinment Plan
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          bgcolor="#434343"
                          color="white"
                          p={1}
                          borderRadius="0px 0px 15px 0px"
                          textAlign="center"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button color="inherit">Own this Business</Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </LoadingOverlay>
        </Container>
        <Departments setCount={setCount}/>
        <DetailAccordion saaheId={saaheId} setSaaheId={setSaaheId} getrating={getrating} ratings={ratings} total={total}/>
        <DetailNearby saaheId={saaheId} setSaaheId={setSaaheId}/>
        <Doctors />
        <LoadingOverlay
          active={loading}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "rgba(255, 255, 255, 1.9)",
            }),
            spinner: (base) => ({
              ...base,
              width: "75px",
              "& svg circle": {
                stroke: "#025b95",
              },
            }),
          }}
          spinner
          text="Loading ..."
        >
          <PackagesCarousel saaheId={saaheId} setSaaheId={setSaaheId}/>
          <Box bgcolor="#fff" >
          <DetailRelated saaheId={saaheId} setSaaheId={setSaaheId} />
          </Box>
        </LoadingOverlay>
      </Box>
      <Footer />
    </div>
  );
}
