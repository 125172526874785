import React, { useState } from "react";
import { useParams } from "react-router-dom";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import image2 from "../../../images/logo.webp";

//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
//import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//import AdapterDateFns from "@mui/lab/AdapterDateFns";
//import LocalizationProvider from "@mui/lab/LocalizationProvider";
//import DatePicker from "@mui/lab/DatePicker";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "../../../utils/Axios"; //import axios config
import { useAlert } from "react-alert";

const useStyle = makeStyles({
  image2: {
    borderRadius: 100,
  },
});

export default function EditPackagesDialog({getPackages}) {
  const [status, setStatus] = React.useState("");

  const StatushandleChange = (event) => {
    setStatus(event.target.value);
  };

  let params = useParams();
  const [saahe_id] = React.useState(params.id ? params.id: "");

  const alert = useAlert();
  const [value, setValue] = React.useState(new Date());
  const [image, setImage] = React.useState("");
  const [banner, setBanner] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [Description, setDescription] = React.useState("");
  const [duration, setDuration] = React.useState("");
  const [fees, setFees] = React.useState("");
  const [uploadImage, setUploadImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");

  const classes = useStyle();
  var loadFile = (event) => {
    if (event.target.files[0]) {
      setUploadImage(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    }
  };

  var loadFile2 = (event) => {
    if (event.target.files[0]) {
      setBannerImage(URL.createObjectURL(event.target.files[0]));
      setBanner(event.target.files[0]);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const submitForm = (event) => {
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("id", saahe_id);
    formdata.append("title", title);
    formdata.append("duration", duration);
    formdata.append("fees", fees);
    formdata.append("logo", image);
    formdata.append("banner", banner);
    formdata.append("Description", Description);
    //formdata.append("awdate", value);
    formdata.append("status", status);
    axios(`Saahe/add_package`, {
      method: "POST",
      data: formdata,
      headers: {
        "Content-Type": "multipart/form-data",
        "x-api-key": JSON.parse(localStorage.getItem("data")),
      },
    })
      .then((res) => {
        if (res.data.status) {
          alert.success(res.data.message);
          getPackages(saahe_id);
          setOpen(false);
        } else {
          alert.error(res.data.message);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        <AddIcon color="inherit" /> Add Packages
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add new Packages</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Title"
                variant="outlined"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Duration"
                variant="outlined"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <TextField
                size="small"
                fullWidth
                required
                label="Fees"
                variant="outlined"
                value={fees}
                onChange={(e) => setFees(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} md={6} lg={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => (
                    <TextField {...props} size="small" fullWidth />
                  )}
                  label="DatePicker"
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                />
              </LocalizationProvider> 
            </Grid>*/}
            <Grid item xs={12} md={6} lg={6}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  label="Status"
                  onChange={StatushandleChange}
                >
                  <MenuItem value={1}>Enable</MenuItem>
                  <MenuItem value={2}>Disable</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image2/*"
                    name="image2"
                    id="file"
                    onChange={loadFile}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">Logo</Typography>

                  <img
                    src={uploadImage ? uploadImage : image2}
                    className={classes.image2}
                    id="output"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Paper style={{ width: "250px" }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    id="file2"
                    onChange={loadFile2}
                    style={{ display: "none" }}
                  />
                  <Typography variant="caption">Banner</Typography>

                  <img
                    src={bannerImage ? bannerImage : image2}
                    className={classes.image2}
                    id="output2"
                    width="200"
                    alt="test"
                  />
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  padding="10px 20px"
                >
                  <label htmlFor="file2" style={{ cursor: "pointer" }}>
                    <PhotoCameraIcon />
                  </label>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            endIcon={<ArrowForwardIcon />}
            size="small"
            variant="contained"
            onClick={submitForm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
